import React, { useState } from 'react';
import './App.css';
import styled from 'styled-components';
import Collages from './components/Collages';
import Amplify, { Auth } from 'aws-amplify';
import { COGNITO } from './configs';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { TextHeader } from './components/Text';
import { Button } from './components/Button';
import Originals from './components/Originals';
import Pipelines from './components/Pipelines';

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
});

function App() {
  const [showCollageTab, setShowCollageTab] = useState(true);
  const [showOriginalsTab, setShowOriginalsTab] = useState(false);
  const [showPipelinesTab, setShowPipelinesTab] = useState(false);

  const disableAllTabs = () => {
    setShowCollageTab(false);
    setShowOriginalsTab(false);
    setShowPipelinesTab(false);
  };
  const signOut = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    Auth.signOut().then(() => window.location.reload());
  };
  return (
    <Container>
      <HeaderWrapper>
        <Navigation>
          <Button
            disabled={false}
            active={showCollageTab}
            onClick={() => {
              disableAllTabs();
              setShowCollageTab(true);
            }}
          >
            Collages
          </Button>
          <Button
            disabled={false}
            active={showOriginalsTab}
            onClick={() => {
              disableAllTabs();
              setShowOriginalsTab(true);
            }}
          >
            Originals
          </Button>
          <Button
            disabled={false}
            active={showPipelinesTab}
            onClick={() => {
              disableAllTabs();
              setShowPipelinesTab(true);
            }}
          >
            Pipelines
          </Button>
        </Navigation>
        <TextHeader>Mannequin internal</TextHeader>
        <SignOut>
          <Button disabled={false} active={true} onClick={signOut}>
            sign out
          </Button>
        </SignOut>
      </HeaderWrapper>
      <LineBreaker />
      {showCollageTab && <Collages />}
      {showOriginalsTab && <Originals />}
      {showPipelinesTab && <Pipelines />}
    </Container>
  );
}

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const HeaderWrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Navigation = styled.div`
  flex: 1;
  display: flex;
  gap: 15px;
`;

const SignOut = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const LineBreaker = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  border-bottom: 1px solid black;
`;

export default withAuthenticator(App);
