import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import MannequinInternalApi from '../../api/MannequinInternalApi';
import { PipelineDto } from '../../api/Types/GetPipelinesResponseDto';
import { Button } from '../Button';
import { TextLabel, TextRegular } from '../Text';
import Pipeline from '../Pipeline';
import ThresholdBox from '../ThresholdBox';

const Pipelines = () => {
  const [pipelines, setPipelines] = useState<Map<string, PipelineDto[]>>(
    new Map(),
  );
  const [linkInput, setLinkInput] = useState('');
  const [pipelinesLoading, setPipelinesLoading] = useState(false);
  const [showFullScreenImage, setShowFullScreenImage] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState('');

  const loadPipelines = () => {
    (async () => {
      setPipelinesLoading(true);
      const pipelinesResponse = await MannequinInternalApi.getPipelines();
      if (pipelinesResponse.data) {
        const responsePipelines = pipelinesResponse.data.pipelines;
        let tempPipelines: Map<string, PipelineDto[]> = new Map();
        if (responsePipelines) {
          for (let key in responsePipelines) {
            tempPipelines.set(key, responsePipelines[key]);
          }
          setPipelines(tempPipelines);
        }
      }
      setPipelinesLoading(false);
    })();
  };

  useEffect(() => {
    loadPipelines();
    // eslint-disable-next-line
  }, []);

  const deletePipeline = async (pipeline_id: string) => {
    setPipelinesLoading(true);
    await MannequinInternalApi.deletePipeline(pipeline_id);
    loadPipelines();
  };

  const registerPipeline = async () => {
    setLinkInput('');
    setPipelinesLoading(true);
    await MannequinInternalApi.registerPipeline(linkInput);
    loadPipelines();
  };

  return (
    <Container>
      {showFullScreenImage && (
        <ThresholdBox
          action={() => {
            setShowFullScreenImage(false);
          }}
        >
          <FullScreenImage
            src={fullScreenImage}
            style={{
              width: fullScreenImage.includes('collage') ? '90%' : '',
              height: fullScreenImage.includes('collage') ? '' : '90%',
            }}
          />
        </ThresholdBox>
      )}
      <RegisterWrapper>
        <RegisterDataWrapper>
          <ParamInput
            placeholder={'google drive link'}
            value={linkInput}
            onChange={(event: any) => setLinkInput(event.target.value)}
          />
          <DropInput
            onClick={() => {
              setLinkInput('');
            }}
          >
            &#10007;
          </DropInput>
        </RegisterDataWrapper>
        <ButtonsWrapper>
          <Button
            onClick={registerPipeline}
            disabled={linkInput.length === 0}
            active={true}
            style={{ width: '25px', height: '25px' }}
          >
            &#10003;
          </Button>
        </ButtonsWrapper>
      </RegisterWrapper>
      <HeaderWrapper>
        <TextRegular>Pipeline inputs review</TextRegular>
        <Button
          disabled={false}
          active={true}
          style={{ width: '22px', height: '22px' }}
          onClick={loadPipelines}
        >
          &#8635;
        </Button>
      </HeaderWrapper>
      <PreviewWrapper>
        {pipelinesLoading ? (
          <StatusContainer>
            <TextLabel>Loading pipelines</TextLabel>
          </StatusContainer>
        ) : pipelines.size > 0 ? (
          <PipelinesContainer>
            {Array.from(pipelines.keys()).map((date: string, key) => {
              return (
                <div key={key}>
                  <TextRegular>{date}</TextRegular>
                  {
                    // @ts-ignore
                    pipelines.get(date).map((pipeline, k) => {
                      return (
                        <Pipeline
                          key={k}
                          pipeline={pipeline}
                          setPipelinesLoading={setPipelinesLoading}
                          loadPipelines={loadPipelines}
                          setFullScreenImage={setFullScreenImage}
                          setShowFullScreenImage={setShowFullScreenImage}
                          deletePipeline={deletePipeline}
                        />
                      );
                    })
                  }
                </div>
              );
            })}
          </PipelinesContainer>
        ) : (
          <StatusContainer>
            <TextLabel>No pipelines checks found</TextLabel>
          </StatusContainer>
        )}
      </PreviewWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

const FullScreenImage = styled.img`
  cursor: zoom-out;
`;

const RegisterWrapper = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  padding: 1%;
  margin-bottom: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RegisterDataWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ButtonsWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
`;

const ParamInput = styled.input`
  width: 600px;
  border: none;
  border-bottom: 1px solid black;

  &:focus {
    outline: none;
  }
`;

const DropInput = styled.div`
  cursor: pointer;
`;

const StatusContainer = styled.div`
  text-align: center;
`;

const PreviewWrapper = styled.div`
  padding: 1%;
  border: 1px solid black;
  border-radius: 10px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 1%;
`;

const PipelinesContainer = styled.div``;

export default Pipelines;
