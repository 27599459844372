import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Button } from '../Button';
import styled from 'styled-components';
import { TextLabel } from '../Text';
import { useDropzone } from 'react-dropzone';

export interface UploadFileProps {
  multiple: boolean;
  onFilesSelected?: (files: File[]) => void;
}

export const UploadFile = (props: UploadFileProps) => {
  const [selectedFiles, setFiles] = useState<File[]>([]);

  const onDrop = useCallback((acceptedFiles: any) => {
    setFiles(selectedFiles.concat(acceptedFiles));
    // eslint-disable-next-line
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: props.multiple,
    onDrop,
    accept: {
      'application/vnd.rar': ['.rar'],
      'application/zip': [],
    },
  });

  useEffect(() => {
    if (props.onFilesSelected) props.onFilesSelected(selectedFiles);
    // eslint-disable-next-line
  }, [selectedFiles]);

  return (
    <Container>
      <DropZone {...getRootProps()}>
        <input {...getInputProps()} />
        <TextLabel>drag and drop files here</TextLabel>
        <SelectFilesButton>
          <Button
            disabled={false}
            active={true}
            style={{ height: '40px', width: '150px' }}
          >
            Browse files
          </Button>
        </SelectFilesButton>
      </DropZone>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 1%;
`;

const DropZone = styled.div`
  height: 60px;
  padding: 1%;
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SelectFilesButton = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 150px;
`;
