import styled from 'styled-components';

const Text = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  text-transform: lowercase;
  color: #000000;
`;

export const TextHeader = styled(Text)`
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
`;

export const TextRegular = styled(Text)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
`;

export const TextLabel = styled(Text)`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
`;
