import createApiClient from './ApiClient';
import { AxiosResponse } from 'axios';
import { GetCollagesResponseDto } from './Types/GetCollagesResponseDto';
import { GetPipelinesResponseDto } from './Types/GetPipelinesResponseDto';

const routes = {
  getUploadUrl: () => `/url/mannequinInternalGetUploadUrl`,

  getCollages: () => `/gc/mannequinInternalGetCollages`,
  registerCollage: () => `/rc/mannequinInternalRegisterCollage`,
  deleteCollage: () => `/dc/mannequinInternalDeleteCollage`,

  getOriginals: () => `/go/mannequinInternalGetOriginals`,
  registerOriginals: () => `/rc/mannequinInternalRegisterOriginals`,
  deleteOriginals: () => `/dc/mannequinInternalDeleteOriginals`,

  getPipelines: () => `/gp/mannequinInternalGetPipelines`,
  deletePipeline: () => `/dp/mannequinInternalDeletePipeline`,
  registerPipeline: () => `/rp/mannequinInternalRegisterPipeline`,
};

export default class MannequinInternalApi {
  private static client = createApiClient();

  public static getUploadUrl(fileName: string) {
    const request = {
      file_name: fileName,
    };
    return this.client.post(routes.getUploadUrl(), request);
  }

  public static getCollages(): Promise<AxiosResponse<GetCollagesResponseDto>> {
    return this.client.get(routes.getCollages());
  }

  public static registerCollage(
    collageId: string,
    collageName: string,
    s3Path: string,
    collageType: string,
  ) {
    const request = {
      collage_id: collageId,
      collage_name: collageName,
      s3_path: s3Path,
      collage_type: collageType,
    };
    return this.client.post(routes.registerCollage(), request);
  }

  public static deleteCollage(collageId: string) {
    const request = {
      collage_id: collageId,
    };
    return this.client.post(routes.deleteCollage(), request);
  }

  public static getOriginals(requestId: string) {
    const request = {
      request_id: requestId,
    };
    return this.client.post(routes.getOriginals(), request);
  }

  public static getOriginalsAsync(originalIds: string[], requestId: string) {
    const request = {
      original_ids: originalIds,
      request_id: requestId,
    };
    const headers = {
      headers: {
        InvocationType: 'Event',
        'Content-Type': 'application/json',
      },
    };
    return this.client.post(routes.getOriginals(), request, headers);
  }

  public static getPipelines(): Promise<
    AxiosResponse<GetPipelinesResponseDto>
  > {
    return this.client.get(routes.getPipelines());
  }

  public static deletePipeline(pipelineId: string) {
    const request = {
      pipeline_id: pipelineId,
    };
    return this.client.post(routes.deletePipeline(), request);
  }

  public static registerPipeline(pipelineLink: string) {
    const request = {
      pipeline_link: pipelineLink,
    };
    return this.client.post(routes.registerPipeline(), request);
  }
}
