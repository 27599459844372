import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UploadFile } from '../SelectFiles';
import { Button } from '../Button';
import { TextLabel, TextRegular } from '../Text';
import { Box, LinearProgress, Typography } from '@mui/material';
import MannequinInternalApi from '../../api/MannequinInternalApi';
import axios from 'axios';
import { CollageDto } from '../../api/Types/GetCollagesResponseDto';
import Collage from '../Collage';
import Select from 'react-select';
import { TYPE_OPTION, ZIP_TYPE_OPTION } from '../../configs';

const Collages = () => {
  const [collages, setCollages] = useState<Map<string, CollageDto[]>>(
    new Map(),
  );
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [fileToUploadState, setFileToUploadState] = useState(0);
  const [fileToUploadType, setFileToUploadType] = useState(TYPE_OPTION.NONE);
  const [fileToUploadProgress, setFileToUploadProgress] = useState(0);
  const [collagesLoading, setCollagesLoading] = useState(false);

  const loadCollages = () => {
    (async () => {
      setCollagesLoading(true);
      const collagesResponse = await MannequinInternalApi.getCollages();
      if (collagesResponse.data) {
        const responseCollages = collagesResponse.data.collages;
        let tempCollages: Map<string, CollageDto[]> = new Map();
        if (responseCollages) {
          for (let key in responseCollages) {
            tempCollages.set(key, responseCollages[key]);
          }
          setCollages(tempCollages);
        }
      }
      setCollagesLoading(false);
    })();
  };

  useEffect(() => {
    loadCollages();
    // eslint-disable-next-line
  }, []);

  const resetFileToUpload = () => {
    setFileToUpload(null);
    setFileToUploadState(0);
    setFileToUploadProgress(0);
    setFileToUploadType(TYPE_OPTION.NONE);
  };

  const onFilesSelected = (selectedFiles: File[]) => {
    selectedFiles.forEach((file: File) => {
      setFileToUpload(file);
    });
  };

  const processZip = () => {
    if (fileToUpload) {
      setFileToUploadState(1);
      getUploadUrl(fileToUpload.name.replace(' ', '_')).then(
        (uploadUrlData) => {
          uploadZipToS3(uploadUrlData.upload_url)
            .then(() => {
              registerZip(uploadUrlData.collage_id, uploadUrlData.s3_path).then(
                () => {
                  resetFileToUpload();
                  loadCollages();
                },
              );
            })
            .catch((error) => {
              console.error(error);
              resetFileToUpload();
            });
        },
      );
    }
  };

  const getUploadUrl = async (fileName: string) => {
    const response = await MannequinInternalApi.getUploadUrl(fileName);
    if (response.status === 200 && response.data.upload_url) {
      return response.data;
    }
  };

  const uploadZipToS3 = async (uploadUrl: string) => {
    if (fileToUpload) {
      let data = new FormData();
      data.append('file', fileToUpload);
      const options = {
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          setFileToUploadProgress(Math.floor((loaded * 100) / total));
        },
      };
      await axios.put(uploadUrl, data, options);
    }
  };

  const registerZip = async (collageId: string, s3Path: string) => {
    if (fileToUpload) {
      await MannequinInternalApi.registerCollage(
        collageId,
        fileToUpload.name.replace(' ', '_'),
        s3Path,
        fileToUploadType,
      );
    }
  };

  return (
    <Container>
      {fileToUploadState === 0 && (
        <UploadFile onFilesSelected={onFilesSelected} multiple={false} />
      )}

      {fileToUpload && (
        <PreviewWrapper>
          <ZipContainer>
            <ZipToUploadPreview>
              <TextLabel style={{ width: '30%' }}>
                {fileToUpload.name}
              </TextLabel>
              {fileToUploadState === 0 ? (
                <ZipData>
                  <TypeWrapper>
                    <TextLabel style={{ width: '50px', marginRight: '10%' }}>
                      Type:{' '}
                    </TextLabel>
                    <Select
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderColor: 'black',
                          boxShadow: 'none',
                          width: '150px',
                          '&:hover': {
                            borderColor: 'black',
                          },
                        }),
                      }}
                      onChange={(selectedOption) =>
                        // @ts-ignore
                        setFileToUploadType(selectedOption.value)
                      }
                      options={ZIP_TYPE_OPTION}
                      defaultValue={{
                        value: TYPE_OPTION.NONE,
                        label: TYPE_OPTION.NONE,
                      }}
                      isMulti={false}
                    ></Select>
                  </TypeWrapper>
                </ZipData>
              ) : (
                <ProgressWrapper>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      paddingRight: '1%',
                    }}
                  >
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <LinearProgress
                        sx={{
                          backgroundColor: `gray`,
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: `black`,
                          },
                        }}
                        variant="determinate"
                        value={fileToUploadProgress}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                      >{`${fileToUploadProgress}%`}</Typography>
                    </Box>
                  </Box>
                </ProgressWrapper>
              )}
              {fileToUploadState === 0 ? (
                <JobToUploadButtons>
                  <Button
                    disabled={fileToUploadType === TYPE_OPTION.NONE}
                    active={true}
                    style={{ width: '22px', height: '22px' }}
                    onClick={() => {
                      processZip();
                    }}
                  >
                    &#10003;
                  </Button>
                  <Button
                    disabled={false}
                    active={true}
                    style={{ width: '22px', height: '22px' }}
                    onClick={() => {
                      resetFileToUpload();
                    }}
                  >
                    &#10007;
                  </Button>
                </JobToUploadButtons>
              ) : (
                <JobToUploadButtons />
              )}
            </ZipToUploadPreview>
          </ZipContainer>
        </PreviewWrapper>
      )}
      <HeaderWrapper>
        <TextRegular>Collages</TextRegular>
        <Button
          disabled={false}
          active={true}
          style={{ width: '22px', height: '22px' }}
          onClick={loadCollages}
        >
          &#8635;
        </Button>
      </HeaderWrapper>
      <PreviewWrapper>
        {collagesLoading ? (
          <StatusContainer>
            <TextLabel>Loading collages</TextLabel>
          </StatusContainer>
        ) : collages.size > 0 ? (
          <CollagesContainer>
            <div>
              <CollageHeader>
                <TextLabel style={{ width: '26%', fontWeight: '400' }}>
                  name
                </TextLabel>
                <TextLabel style={{ width: '10%', fontWeight: '400' }}>
                  type
                </TextLabel>
                <TextLabel style={{ width: '11%', fontWeight: '400' }}>
                  state
                </TextLabel>
                <TextLabel style={{ width: '13%', fontWeight: '400' }}>
                  results
                </TextLabel>
                <TextLabel style={{ width: '13%', fontWeight: '400' }}>
                  progress
                </TextLabel>
              </CollageHeader>
            </div>
            {Array.from(collages.keys()).map((date: string, key) => {
              return (
                <div key={key}>
                  <TextRegular>{date}</TextRegular>
                  {
                    // @ts-ignore
                    collages.get(date).map((collage, k) => {
                      return (
                        <Collage
                          key={k}
                          collage={collage}
                          setCollagesLoading={setCollagesLoading}
                          loadCollages={loadCollages}
                        />
                      );
                    })
                  }
                </div>
              );
            })}
          </CollagesContainer>
        ) : (
          <StatusContainer>
            <TextLabel>No collages found</TextLabel>
          </StatusContainer>
        )}
      </PreviewWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 1% 1% 0 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

const StatusContainer = styled.div`
  text-align: center;
`;

const PreviewWrapper = styled.div`
  padding: 1%;
  margin-bottom: 1%;
  border: 1px solid black;
  border-radius: 10px;
`;

const ZipContainer = styled.div`
  align-items: center;
`;

const ZipToUploadPreview = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
`;

const ZipData = styled.div`
  width: 65%;
  display: flex;
`;

const TypeWrapper = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
`;

const ProgressWrapper = styled.div`
  width: 65%;
`;

const JobToUploadButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 4%;
  margin-left: 1%;
  gap: 5px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 1%;
`;

const CollagesContainer = styled.div``;
const CollageHeader = styled.div`
  padding-left: 3%;
  width: 97%;
  display: flex;
  border-bottom: 1px solid black;
`;

export default Collages;
