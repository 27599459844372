import styled from "styled-components";
import { TextLabel } from "../Text";
import { Button, PointerButton } from "../Button";
import React, { useState } from "react";
import { CollageDto } from "../../api/Types/GetCollagesResponseDto";
import MannequinInternalApi from "../../api/MannequinInternalApi";
import ThresholdBox from "../ThresholdBox";

type Props = {
  collage: CollageDto;
  setCollagesLoading: (choice: boolean) => void;
  loadCollages: () => void;
};
const Collage = (props: Props) => {
  const { collage, setCollagesLoading, loadCollages } = props;
  const [showOutput, setShowOutput] = useState(false);
  const deleteCollage = async () => {
    setCollagesLoading(true);
    await MannequinInternalApi.deleteCollage(collage.collage_id);
    loadCollages();
  };

  const progressDetails = (): string => {
    return (
      "Success: " +
      collage.success +
      ", Failed: " +
      collage.failed +
      " of Total: " +
      collage.total
    );
  };

  const getFailedProgress = (): number => {
    let progress = parseInt(collage.failed) + parseInt(collage.success);
    return Math.floor((progress * 100) / parseInt(collage.total));
  };

  const getSuccessProgress = (): number => {
    let total = parseInt(collage.failed) + parseInt(collage.success);
    return Math.floor((parseInt(collage.success) * 100) / total);
  };

  return (
    <Container>
      {showOutput && (
        <ThresholdBox
          action={() => {
            setShowOutput(false);
          }}
        >
          <OutputTextArea
            onClick={(e) => {
              e.stopPropagation();
            }}
            defaultValue={collage.output}
          />
        </ThresholdBox>
      )}
      <CollageData>
        <TextLabel
          style={{ width: "26%", overflow: "hidden", textOverflow: "ellipsis" }}>{collage.collage_name}</TextLabel>
        <TextLabel style={{ width: "10%" }}>{collage.collage_type}</TextLabel>
        {collage.state === "failed" || collage.state === "finished" ? (
          <PointerButton
            style={{ width: "11%" }}
            onClick={() => {
              setShowOutput(true);
            }}
          >
            <TextLabel
              style={collage.state === "failed" ? { color: "red" } : {}}
            >
              {collage.state}
            </TextLabel>
          </PointerButton>
        ) : (
          <TextLabel style={{ width: "11%" }}>{collage.state}</TextLabel>
        )}

        <TextLabel style={{ width: "12%" }}>
          {collage.state === "finished" && (
            <DownloadButton href={collage.results}>
              download results
            </DownloadButton>
          )}
        </TextLabel>
        <ProgressContainer>
          {collage.total && (
            <ProgressWrapper title={progressDetails()}>
              <ProgressBar width={getFailedProgress()} failed={true}>
                <ProgressBar width={getSuccessProgress()} failed={false} />
              </ProgressBar>
            </ProgressWrapper>
          )}
        </ProgressContainer>
        <CollageButtons>
          <Button
            disabled={
              collage.state === "processing" || collage.state === "scheduled"
            }
            active={true}
            style={{ width: "22px", height: "22px" }}
            onClick={() => deleteCollage()}
          >
            &#10007;
          </Button>
        </CollageButtons>
      </CollageData>
    </Container>
  );
};

const Container = styled.div`
  padding-left: 3%;
  width: 97%;
`;

const CollageData = styled.div`
  display: flex;
`;

const CollageButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //width: 48%;
  gap: 10px;
`;

export const DownloadButton = styled.a`
  cursor: pointer;
  color: black;
  text-decoration-line: underline;
  text-decoration-style: dotted;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
`;

const OutputTextArea = styled.textarea`
  width: 70%;
  height: 70%;
  padding: 20px;
`;

const ProgressContainer = styled.div`
  width: 39%;
  align-items: center;
  display: flex;
`;

const ProgressWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 13px;
  border-radius: 5px;
  background-color: #7a7a7a;
  margin: 0 3%;
`;

const ProgressBar = styled.div<{ width: number; failed: boolean }>`
  display: flex;
  border-radius: 5px;
  width: ${(props) => props.width}%;
  height: 100%;
  background-color: ${(props) => (props.failed ? "red" : "#151515;")};
`;

export default Collage;
