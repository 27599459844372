import React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  action: { (): void } | null;
};

const ThresholdBox: React.FC<Props> = ({ children, action }) => {
  // @ts-ignore
  return action === null ? <Container>{children}</Container> : <Container onClick={action}>{children}</Container>;
};

const Container = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ThresholdBox;
export const ThresholdContainer = styled.div`
  position: relative;
  width: 70%;
  max-height: 70%;
  overflow: scroll;
  background: white;
  border-radius: 10px;
  padding: 20px;
`;
