export const COGNITO = {
  REGION: 'us-west-2',
  USER_POOL_ID: 'us-west-2_U9Rnl6ye1',
  APP_CLIENT_ID: '515hp9asqg1e4f52g37vtgbbmu',
};

export enum TYPE_OPTION {
  NONE = 'none',
  SKIN = 'skin',
  HUMAN = 'h-p',
  EMAP = 'e-map',
  MNQNS = 'mnqns',
}

export const ZIP_TYPE_OPTION = [
  { value: TYPE_OPTION.NONE, label: TYPE_OPTION.NONE },
  { value: TYPE_OPTION.SKIN, label: TYPE_OPTION.SKIN },
  { value: TYPE_OPTION.HUMAN, label: TYPE_OPTION.HUMAN },
  { value: TYPE_OPTION.EMAP, label: TYPE_OPTION.EMAP },
  { value: TYPE_OPTION.MNQNS, label: TYPE_OPTION.MNQNS },
];
