import styled from 'styled-components';
import { TextLabel } from '../Text';
import { Button, PointerButton } from '../Button';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import MannequinInternalApi from '../../api/MannequinInternalApi';

const load = require('../../assets/load.gif');

const Originals = () => {
  const [originalIds, setOriginalIds] = useState<string[]>([]);
  const [originalIdsInput, setOriginalIdsInput] = useState('');
  const [originalsLoading, setOriginalsLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles: any) => {
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      // @ts-ignore
      // noinspection JSVoidFunctionReturnValueUsed
      const csv = Papa.parse(target.result, { header: true });
      // @ts-ignore
      const parsedData: any[] = csv?.data;
      let indexes: string[] = [];

      parsedData.forEach((entry) => {
        let index = entry.index;
        if (index && index.length > 0) {
          indexes.push(index);
        }
      });
      setOriginalIds(indexes);
      setOriginalIdsInput(indexes.toString());
    };
    reader.readAsText(acceptedFiles[0]);
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    noDrag: true,
    multiple: false,
    onDrop,
    accept: {
      'text/csv': [],
    },
  });

  const parseOriginalIdsInput = (inputValue: string) => {
    let indexes: string[] = [];
    inputValue.split(',').forEach((index) => {
      if (index.trim().length > 0) {
        indexes.push(index.trim());
      }
    });
    setOriginalIds(indexes);
    setOriginalIdsInput(inputValue);
  };

  const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const padTo2Digits = (num: number) => num.toString().padStart(2, '0');
  const getRequest_id = () => {
    const date = new Date();
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('_') +
      '_' +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join('_')
    );
  };

  const getOriginals = async () => {
    setOriginalsLoading(true);
    let url = '';
    const requestId = getRequest_id();
    await MannequinInternalApi.getOriginalsAsync(originalIds, requestId).then(
      async () => {
        let archiveIsReady = false;
        let repeats = 0;
        do {
          await delay(5000);
          await MannequinInternalApi.getOriginals(requestId).then((data) => {
            if (data.data.presignedUrl) {
              archiveIsReady = true;
              url = data.data.presignedUrl;
            }
            repeats += 1;
          });
        } while (!archiveIsReady && repeats < 30);
      },
    );
    window.open(url, '_top');
    setOriginalIds([]);
    setOriginalIdsInput('');
    setOriginalsLoading(false);
  };

  return (
    <Container>
      {originalsLoading ? (
        <StatusContainer>
          <img src={load} alt="img" />
        </StatusContainer>
      ) : (
        <RegisterWrapper>
          <RegisterDataWrapper>
            <ParamInput
              placeholder={'original ids'}
              value={originalIdsInput}
              onChange={(event: any) =>
                parseOriginalIdsInput(event.target.value)
              }
            />
            <DropInput
              onClick={() => {
                setOriginalIds([]);
                setOriginalIdsInput('');
              }}
            >
              &#10007;
            </DropInput>
            <LoadFomCsvButton {...getRootProps()}>
              <input {...getInputProps()} />
              <PointerButton>
                <TextLabel>load from csv</TextLabel>
              </PointerButton>
            </LoadFomCsvButton>
          </RegisterDataWrapper>
          <ButtonsWrapper>
            <Button
              onClick={() => {
                getOriginals();
              }}
              disabled={originalIds.length === 0}
              active={true}
              style={{ width: '25px', height: '25px' }}
            >
              &#10003;
            </Button>
          </ButtonsWrapper>
        </RegisterWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

const StatusContainer = styled.div`
  text-align: center;
  margin-bottom: 1%;
`;

const RegisterWrapper = styled.div`
  padding-top: 1%;
  padding-bottom: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RegisterDataWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ButtonsWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
`;

const ParamInput = styled.input`
  width: 450px;
  border: none;
  border-bottom: 1px solid black;

  &:focus {
    outline: none;
  }
`;

const DropInput = styled.div`
  cursor: pointer;
`;

const LoadFomCsvButton = styled.div``;

export default Originals;
