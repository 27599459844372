import styled from 'styled-components';
import { PipelineDto } from '../../api/Types/GetPipelinesResponseDto';
import { TextLabel } from '../Text';
import { PointerButton } from '../Button';

type Props = {
  pipeline: PipelineDto;
  setPipelinesLoading: (choice: boolean) => void;
  setShowFullScreenImage: (choice: boolean) => void;
  setFullScreenImage: (img: string) => void;
  loadPipelines: () => void;
  deletePipeline: (pipeline_id: string) => void;
};
const Pipeline = (props: Props) => {
  return (
    <Container>
      <PipelineWrapper>
        <PipelineNameWithActions>
          <TextLabel style={{ width: '350px' }}>
            <DownloadButton href={props.pipeline.original_link} target="_blank">
              {props.pipeline.pipeline_id}
            </DownloadButton>
          </TextLabel>
          <TextLabel style={{ width: '200px' }}>
            State: {props.pipeline.state}
          </TextLabel>
          <TextLabel style={{ width: '140px' }}>
            {props.pipeline.results_zip && (
              <DownloadButton href={props.pipeline.results_zip}>
                download results
              </DownloadButton>
            )}
          </TextLabel>
          <TextLabel>
            {props.pipeline.state === 'finished' && (
              <PointerButton
                style={{ marginLeft: '100px' }}
                onClick={() => {
                  props.deletePipeline(props.pipeline.pipeline_id);
                }}
              >
                Delete
              </PointerButton>
            )}
          </TextLabel>
        </PipelineNameWithActions>
        {props.pipeline.results && (
          <RenderResultsWrapper>
            <ImagesContainer>
              {props.pipeline.results.map((result, key) => {
                return (
                  <ImageContainer key={key}>
                    <ImgWrapper>
                      <Img
                        onClick={() => {
                          props.setFullScreenImage(result);
                          props.setShowFullScreenImage(true);
                        }}
                        src={result}
                        alt="img"
                      />
                    </ImgWrapper>
                    <TextLabel>
                      {result.split('/').pop()?.split('.')[0]}
                    </TextLabel>
                  </ImageContainer>
                );
              })}
            </ImagesContainer>
          </RenderResultsWrapper>
        )}
      </PipelineWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding-left: 3%;
  width: 97%;
`;

const PipelineWrapper = styled.div`
  border-bottom: 1px solid black;
  padding: 1% 0;
  align-items: center;
`;

const PipelineNameWithActions = styled.div`
  align-items: center;
  display: flex;
`;

export const DownloadButton = styled.a`
  cursor: pointer;
  color: black;
  text-decoration-line: underline;
  text-decoration-style: dotted;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
`;

const RenderResultsWrapper = styled.div`
  margin-top: 10px;
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const ImagesContainer = styled.div`
  display: flex;
`;

const ImageContainer = styled.div`
  padding: 10px;
  background: white;
  border: 1px solid black;
  font-size: 20px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  text-align: center;
`;

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 150px;
  height: 150px;
  align-items: center;
  margin: auto;
`;

const Img = styled.img`
  height: 100%;
  cursor: zoom-in;
`;

export default Pipeline;
